import LoadingSkeleton from 'components/LoadingSkeleton';
import { CardsInRowProps } from './props';

export const breakpoints = {
  xs: '(max-width: 639px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1536px)',
};

export const getCardsInRow = (cardsInRow: CardsInRowProps, breakpoint: keyof CardsInRowProps) => {
  const orderedBreakpoints: (keyof CardsInRowProps)[] = [
    'xxl',
    'xl',
    'lg',
    'md',
    'sm',
    'xs',
    'base',
  ];

  const nearestBreakpoint = orderedBreakpoints
    .slice(orderedBreakpoints.indexOf(breakpoint))
    .find((bp) => cardsInRow[bp] !== undefined);

  return cardsInRow[nearestBreakpoint || 'base'];
};

export const CardLoadingSkeleton = () => {
  return (
    <div className="flex min-h-[28rem] flex-col gap-4">
      <div className="min-h-0 shrink-0 basis-[5.5rem]">
        <LoadingSkeleton count={3} />
      </div>
      <LoadingSkeleton containerClassName="flex-1 shrink-0" className="h-full" />
    </div>
  );
};
