import { VideoAnalysisSwitcherProps, VideoAnalysisTabEnum } from './types';

const VideoAnalysisSwitcher: React.FC<VideoAnalysisSwitcherProps> = ({
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="relative mx-auto flex flex-col items-center justify-center whitespace-nowrap rounded-3xl bg-color-bg-lightmode-secondary p-0.5 dark:bg-color-bg-darkmode-secondary xs:w-full md:w-80">
      <div className="relative flex w-full flex-1 justify-center">
        <div
          className={`absolute bottom-0 left-0 top-0 w-1/2 rounded-3xl transition-transform duration-300 ${
            activeTab === VideoAnalysisTabEnum.Pending ? 'translate-x-0' : 'translate-x-full'
          } bg-color-bg-lightmode-brand dark:bg-color-bg-darkmode-brand`}
        ></div>
        <button
          onClick={() => setActiveTab(VideoAnalysisTabEnum.Pending)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 xs:h-8 md:h-10 ${
            activeTab === VideoAnalysisTabEnum.Pending
              ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          Pending
        </button>
        <button
          onClick={() => setActiveTab(VideoAnalysisTabEnum.Reviewed)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 xs:h-8 md:h-10 ${
            activeTab === VideoAnalysisTabEnum.Reviewed
              ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          Reviewed
        </button>
      </div>
    </div>
  );
};

export default VideoAnalysisSwitcher;
