import * as React from 'react';

export default function ArrowUp({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-up">
        <path
          id="Icon"
          d="M9.99996 15.8346V4.16797M9.99996 4.16797L4.16663 10.0013M9.99996 4.16797L15.8333 10.0013"
          stroke="#17B26A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
