import React from 'react';
import { LessonTypesEnum } from 'types/generated/client';
import toTitleCase from 'utils/shared/string/toTitleCase';
import PickleRacket from 'svg/PickleRacket';
import classNames from 'styles/utils/classNames';
import { LessonBadgeProps } from './types';

const variantIcons: { [key in LessonTypesEnum]: any } = {
  [LessonTypesEnum.Custom]: PickleRacket,
  [LessonTypesEnum.Clinic]: PickleRacket,
  [LessonTypesEnum.Cardio]: PickleRacket,
  [LessonTypesEnum.Retreat]: PickleRacket,
  [LessonTypesEnum.Camp]: PickleRacket,
  [LessonTypesEnum.Individual]: PickleRacket,
};

export default function LessonBadge({
  label,
  variant = LessonTypesEnum.Custom,
  size = 'md',
  className,
  ...rest
}: LessonBadgeProps) {
  const Icon = variantIcons[variant] || PickleRacket;

  return (
    <div
      className={classNames(
        'typography-product-chips-filters flex w-fit items-center justify-start gap-2 rounded-full p-2',

        !!size && size === 'xs' && 'typography-product-button-label-xs px-2 py-0.5',
        !!size && size === 'sm' && 'typography-product-button-label-small px-2.5 py-1',
        !!size && size === 'md' && 'typography-product-subheading px-3 py-1.5',
        !!size && size === 'lg' && 'typography-product-heading-compact px-8 py-4',

        variant === LessonTypesEnum.Camp &&
          'bg-green-100 text-green-600 dark:bg-green-100 dark:text-green-600',
        variant === LessonTypesEnum.Individual &&
          'bg-yellow-100 text-yellow-600 dark:bg-yellow-100 dark:text-yellow-600',
        variant === LessonTypesEnum.Clinic &&
          'bg-blue-100 text-blue-600 dark:bg-blue-100 dark:text-blue-600',
        variant === LessonTypesEnum.Custom &&
          'bg-red-100 text-purple-600 dark:bg-purple-100 dark:text-purple-600',
        variant === LessonTypesEnum.Cardio &&
          'bg-red-100 text-red-600 dark:bg-red-100 dark:text-red-600',
      )}
      {...rest}
    >
      <Icon
        className={classNames(
          !!size && size === 'xs' && 'h-3.5 w-3.5',
          !!size && size === 'sm' && 'h-5 w-5',
          !!size && size === 'md' && 'h-5.5 w-5.5',
          !!size && size === 'lg' && 'h-6 w-6',
        )}
      />
      <span className="flex w-full flex-row overflow-hidden whitespace-nowrap p-0">
        {label} - {toTitleCase(variant)}
      </span>
    </div>
  );
}
