import React from 'react';

interface TimeRangeOption {
  label: string;
  value: string;
}

interface TimeRangeSelectorProps {
  options: TimeRangeOption[];
  selectedValue: string;
  onSelect: (value: string) => void;
}

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  options,
  selectedValue,
  onSelect,
}) => {
  return (
    <div className="typography-product-text-card flex items-end justify-center gap-3 rounded-lg border bg-color-bg-lightmode-secondary px-1 py-2 text-center text-color-text-lightmode-primary dark:bg-color-bg-darkmode-secondary dark:text-color-text-darkmode-primary xs:w-full md:w-fit">
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => onSelect(option.value)}
          className={`typography-product-chips-filters font-normal gap-1 self-stretch whitespace-nowrap rounded-lg bg-color-bg-lightmode-primary px-4 py-1 shadow-sm dark:bg-color-bg-darkmode-primary ${
            selectedValue === option.value
              ? 'border border-solid border-color-border-card-darkmode dark:border-color-border-card-lightmode'
              : ''
          }`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default TimeRangeSelector;
