import React from 'react';
import NextImage from 'next/image';

interface UserAvatarProps {
  name: string;
  image: string;
  isSelected: boolean;
  onClick: () => void;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ name, image, isSelected, onClick }) => {
  return (
    <div className="h-fit w-fit">
      <button
        type="button"
        onClick={onClick}
        className={`flex flex-col items-center justify-center gap-1 rounded-lg border ${
          isSelected
            ? 'rounded-lg border border-color-border-card-darkmode bg-color-bg-lightmode-secondary dark:border-color-border-card-darkmode  dark:bg-color-bg-darkmode-secondary'
            : 'border-color-border-card-lightmode hover:border-color-border-card-darkmode hover:bg-color-bg-lightmode-secondary dark:border-color-border-card-darkmode dark:hover:border-color-border-card-lightmode hover:dark:bg-color-bg-darkmode-secondary'
        } xs:h-[4.25rem] xs:w-[4.5rem] xs:px-2 xs:py-1.5 md:h-[6.25rem] md:w-[6.25rem] md:px-0 md:py-0`}
      >
        {name !== 'All' && (
          <>
            <NextImage
              loading="lazy"
              width={64}
              height={64}
              src={image}
              alt={`Avatar of ${name}`}
              className="hidden aspect-square rounded-full object-cover md:inline"
            />

            <NextImage
              src={image}
              alt={`Avatar of ${name}`}
              className="inline aspect-square rounded-full object-cover md:hidden"
              loading="lazy"
              width={40}
              height={40}
            />

            <p className="md:typography-product-chips-filters xs:typography-product-tabbar-mobile w-14 overflow-hidden text-ellipsis whitespace-nowrap text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              {name}
            </p>
          </>
        )}

        {name === 'All' && (
          <>
            <p className="typography-product-chips-filters hidden overflow-hidden text-ellipsis whitespace-nowrap text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary md:block">
              All Players
            </p>

            <p className="typography-product-tabbar-mobile block w-14 overflow-hidden text-ellipsis whitespace-nowrap text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary md:hidden">
              All
            </p>
          </>
        )}
      </button>
    </div>
  );
};
export default UserAvatar;
