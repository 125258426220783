import React, { RefObject, useEffect, useRef, useState } from 'react';
import ChevronLeft from 'svg/ChevronLeft';
import ChevronRight from 'svg/ChevronRight';
import UserAvatar from './UserAvatar';
import { Player, PlayerListProps } from '../types';

const PlayerList: React.FC<PlayerListProps> = ({ players, selectedPlayer, setSelectedPlayer }) => {
  const scrollContainerRefEvents = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedPlayer?.name === '' && players) {
      setSelectedPlayer(players[0]);
    }
  }, [players, selectedPlayer]);

  const createScrollHandlers = (
    ref: RefObject<HTMLDivElement>,
    setIsScrolledToStart: React.Dispatch<React.SetStateAction<boolean>>,
    setIsScrolledToEnd: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const scrollLeft = () => {
      if (ref.current) {
        ref.current.scrollBy({
          left: -360,
          behavior: 'smooth',
        });
      }
    };

    const scrollRight = () => {
      if (ref.current) {
        ref.current.scrollBy({
          left: 360,
          behavior: 'smooth',
        });
      }
    };

    const handleScroll = () => {
      if (ref.current) {
        const { scrollLeft, scrollWidth, clientWidth } = ref.current;
        setIsScrolledToStart(scrollLeft === 0);
        setIsScrolledToEnd(scrollLeft + clientWidth >= scrollWidth);
      }
    };

    return { scrollLeft, scrollRight, handleScroll };
  };

  const Wrapper = ({
    children,
    scrollContainerRef,
  }: {
    children: React.ReactNode;

    scrollContainerRef: React.RefObject<HTMLDivElement>;
  }) => {
    const [isScrolledToStart, setIsScrolledToStart] = useState(true);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const { scrollLeft, scrollRight, handleScroll } = createScrollHandlers(
      scrollContainerRef,
      setIsScrolledToStart,
      setIsScrolledToEnd,
    );

    useEffect(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check
      }

      return () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, [scrollContainerRef, handleScroll]);

    return (
      <div className="relative">
        {!isScrolledToStart && (
          <button
            onClick={scrollLeft}
            className="absolute top-2/4 z-30 -translate-y-1/2 cursor-pointer rounded-full bg-color-bg-darkmode-invert p-3 dark:bg-color-bg-lightmode-invert xs:left-[5%] md:left-[0.25%]"
          >
            <ChevronLeft className="h-5 w-5 text-color-text-darkmode-invert dark:text-color-text-lightmode-invert" />
          </button>
        )}
        <div
          ref={scrollContainerRef}
          className="follow-scrollbar w-full max-w-full overflow-x-auto scroll-smooth"
        >
          {children}
        </div>
        {!isScrolledToEnd && (
          <button
            onClick={scrollRight}
            className="absolute top-2/4 z-30 -translate-y-1/2 cursor-pointer rounded-full bg-color-bg-darkmode-invert p-3 text-color-text-darkmode-invert dark:bg-color-bg-lightmode-invert dark:text-color-text-lightmode-invert xs:right-[5%] md:right-[0.25%]"
          >
            <ChevronRight className="h-5 w-5 text-color-text-darkmode-invert dark:text-color-text-lightmode-invert" />
          </button>
        )}
      </div>
    );
  };

  const handleSelection = (player: Player) => {
    if (player?.id !== selectedPlayer?.id) {
      setSelectedPlayer(player);
    }
  };

  return (
    <>
      <section className="flex w-full flex-col items-center bg-color-bg-lightmode-primary  dark:bg-color-bg-darkmode-primary">
        <div className="flex flex-row items-center justify-center xs:mb-8 xs:w-[98%] xs:gap-1 md:my-0 md:w-[83%] md:gap-3 lg:w-[90%]">
          <div className="relative flex w-full flex-col ">
            <Wrapper scrollContainerRef={scrollContainerRefEvents}>
              <div className="flex w-full xs:gap-1 md:gap-3">
                {players?.map((player) => (
                  <div key={player.id}>
                    <UserAvatar
                      key={player.id}
                      name={player.name}
                      image={player.profileImage}
                      isSelected={player.id === selectedPlayer?.id}
                      onClick={() => handleSelection(player)}
                    />
                  </div>
                ))}
              </div>
            </Wrapper>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlayerList;
