function toTitleCase(input: string): string {
  return input
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
}

export default toTitleCase;
