import React from 'react';
import { NEW_LESSON_PAGE } from 'constants/pages';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import Link from 'components/Link';

const CreateLesson: React.FC = () => {
  return (
    <Link href={NEW_LESSON_PAGE}>
      <div className="mt-6 flex h-fit flex-row items-center justify-center gap-3 rounded-lg border border-color-border-card-lightmode bg-color-bg-lightmode-primary px-24 py-6 shadow-md dark:border-color-border-card-darkmode dark:bg-color-bg-darkmode-primary">
        <div className="flex h-11 shrink-0 grow-0 items-center justify-center rounded-full border-none bg-color-brand-primary px-3 shadow-lg">
          <PlusWithoutBG className="h-6 w-6 cursor-pointer" />
        </div>
        <div className="typography-product-body-highlight flex w-fit shrink-0 grow-0 flex-nowrap text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          Create lesson
        </div>
      </div>
    </Link>
  );
};

export default CreateLesson;
