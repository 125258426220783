import React from 'react';
import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CoachLessonRevenueTypesEnum } from 'utils/shared/coach/constants';
import toTitleCase from 'utils/shared/string/toTitleCase';
import { StatsCardGraphProps } from '../types';

const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-10}
        y={0}
        dy={12}
        textAnchor="middle"
        fill="#000"
        style={{ fontSize: '0.75rem', fontWeight: '400' }}
      >
        {payload.value}
      </text>
    </g>
  );
};

const StatsCardGraph: React.FC<StatsCardGraphProps> = ({ data, title }) => {
  const LegendContent = (props: any) => {
    const { payload } = props;
    return (
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
          justifyContent: 'center',
          display: 'flex',
          gap: '1rem',
        }}
      >
        {payload.map((entry: any, index: number) => {
          const color =
            entry.value === 'group' ? '#E7E7E9' : entry.value === 'private' ? '#FFD3B8' : '#000';
          return (
            <li
              key={`item-${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                gap: '0.5rem',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '1.25rem',
                  height: '0.625rem',
                  backgroundColor: color,
                  borderRadius: '0.5rem',
                  marginRight: '0.125rem',
                }}
              />
              <span>{toTitleCase(entry.value)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={342}
            height={300}
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <XAxis tick={CustomizedAxisTick} dataKey="name" axisLine={false} tickLine={false} />
            <YAxis
              tick={CustomizedAxisTick}
              axisLine={false}
              tickLine={false}
              label={{
                value:
                  title === CoachLessonRevenueTypesEnum.Lessons
                    ? 'lesson per day'
                    : 'revenue per month, $',
                angle: -90,
                position: 'insideLeft',
                style: {
                  textAnchor: 'middle',
                  fontSize: '0.625rem',
                  fontWeight: '500',
                },
                fill: '#98989F',
              }}
            />
            <Tooltip />
            <Legend
              content={<LegendContent />}
              layout="horizontal"
              verticalAlign="top"
              align="center"
            />
            {/* <Legend /> */}
            <Bar
              dataKey="group"
              fill="#E7E7E9"
              activeBar={<Rectangle fill="#E7E7E9" stroke="#E7E7E9" />}
            />
            <Bar
              dataKey="private"
              fill="#FFD3B8"
              activeBar={<Rectangle fill="#FFD3B8" stroke="#FFD3B8" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StatsCardGraph;
