import Image from 'next/image';
import { getVideoAnalysisPageUrl } from 'constants/pages';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import ArrowRight from 'svg/ArrowRight';
import Calendar from 'svg/Calendar';
import { formatDate } from 'screens/TournamentDetails/utils';
import Link from 'components/Link';
import Pill from 'components/Pill';
import classNames from 'styles/utils/classNames';
import { AnalysisCardProps } from './types';
import { getFormattedSkills } from './utils';

const AnalysisCard: React.FC<AnalysisCardProps> = ({
  analysisData,
  ctaText,
  isPlayerViewing,
  headerRight,
  hideAvatar = false,
}) => {
  const {
    submissionRequestedFeedback,
    videoAnalysisFiles,
    createdAt,
    videoAnalysisSubmission,
    videoAnalysisReviewer,
  } = analysisData;

  const skills = getFormattedSkills(analysisData);

  const profileImageUrl = isPlayerViewing
    ? videoAnalysisReviewer?.profileImageProviderUrl
    : videoAnalysisSubmission?.profileImageProviderUrl;

  const profileImageAlt = isPlayerViewing
    ? videoAnalysisReviewer?.profileImageFileName
    : videoAnalysisSubmission?.profileImageFileName;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <div className="flex gap-3">
          {hideAvatar === false && (
            <img
              src={profileImageUrl || EMPTY_AVATAR_SRC}
              alt={profileImageAlt || 'profile-image'}
              className="h-12 w-12 rounded-full"
            />
          )}
          <div>
            {hideAvatar === false && (
              <span className="typography-product-subheading capitalize">
                {(isPlayerViewing
                  ? videoAnalysisReviewer?.fullName
                  : videoAnalysisSubmission?.fullName) || ''}
              </span>
            )}
            <div className="mt-1 flex items-center gap-1">
              <Calendar className="h-5 w-5 text-color-text-lightmode-tertiary" />
              {createdAt && (
                <span
                  className={classNames(
                    'typography-product-text-card text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
                    hideAvatar && 'typography-product-card-title',
                  )}
                >
                  {formatDate({ date: createdAt })}
                </span>
              )}
            </div>
          </div>
        </div>
        {headerRight}
      </div>

      <div className="flex flex-wrap gap-2">
        {skills.map((skill, index) => (
          <Pill text={skill!} key={index} />
        ))}
      </div>

      <p className="typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {submissionRequestedFeedback}
      </p>

      <video
        width="100%"
        controls
        className="mt-auto h-80 rounded-xl object-contain"
        src={videoAnalysisFiles.url}
      />

      {ctaText && (
        <div className="flex w-full justify-end">
          <Link
            href={getVideoAnalysisPageUrl(analysisData.id)}
            className="typography-product-button-label-large mt-1 inline-flex items-center gap-1"
          >
            {ctaText} <ArrowRight className="h-5 w-5" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default AnalysisCard;
