import React from 'react';
import { Area, AreaChart, Tooltip } from 'recharts';
import { CoachLessonRevenueTypesEnum } from 'utils/shared/coach/constants';
import { StatsCardChartProps } from '../types';

const StatsCardChart: React.FC<StatsCardChartProps> = ({
  playerData,
  activeTab,
  isProfit,
}) => {
  return (
    <div>
      <AreaChart
        width={96}
        height={48}
        data={
          activeTab === CoachLessonRevenueTypesEnum.Lessons
            ? playerData?.lessonData
            : playerData?.revenueData
        }
      >
        <Tooltip />
        <Area
          type="monotone"
          dataKey={activeTab === CoachLessonRevenueTypesEnum.Lessons ? 'lesson' : 'group'}
          stroke={isProfit ? '#349D80' : '#E1475F'}
          fill={isProfit ? '#D6EBE6' : '#F9DADF'}
        />
      </AreaChart>
    </div>
  );
};

export default StatsCardChart;
