import React, { useEffect, useState } from 'react';
import { CoachLessonRevenueTypesEnum, TimeRangeOptionsEnums } from 'utils/shared/coach/constants';
import toTitleCase from 'utils/shared/string/toTitleCase';
import ArrowDown from 'svg/ArrowDown';
import ArrowUp from 'svg/ArrowUp';
import classNames from 'styles/utils/classNames';
import { LessonStatsCard } from './LessonStatsCard';
import { RevenueStatsCard } from './RevenueStatsCard';
import StatsCardChart from './StatsCardChart';
import StatsCardGraph from './StatsCardGraph';
import { TimeRangeSelector } from './TimeRangeSelector';
import { BarDataProps, StatsCardProps } from '../types';

const filterDataByTimeRange = (data: BarDataProps[], timeRange: string) => {
  // Rotate the data so that the current month is at the end
  const rotatedData = data;

  switch (timeRange) {
    case '7d':
    case '1m':
      return rotatedData.slice(-1); // Last month
    case '3m':
      return rotatedData.slice(-3); // Last 3 months
    case '6m':
      return rotatedData.slice(-6); // Last 6 months
    case 'all':
    default:
      return rotatedData; // All months
  }
};

export const StatsCard: React.FC<StatsCardProps> = ({
  title,
  count,
  percentage,
  isProfit,
  activeTab,
  openCoachActionsModal,
  playerData,
  rankingsData,
}) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState('all');
  const [filteredData, setFilteredData] = useState<BarDataProps[]>();

  useEffect(() => {
    const data = filterDataByTimeRange(
      activeTab === CoachLessonRevenueTypesEnum.Lessons
        ? playerData?.lessonBarData || []
        : playerData?.revenueBarData || [],
      selectedTimeRange,
    );
    setFilteredData(data);
  }, [selectedTimeRange, playerData]);

  return (
    <>
      <div className="flex gap-6 xs:flex-col-reverse md:mx-0 md:flex-col lg:mx-12 lg:flex-row">
        <div className="w-full md:w-full lg:w-2/3">
          {/* Bar Graph */}
          <section className="flex flex-col rounded-xl bg-color-bg-lightmode-primary md:border md:border-color-border-card-lightmode md:p-6 md:pb-10 md:pt-5 md:shadow-sm md:dark:border-color-border-card-darkmode">
            {activeTab === CoachLessonRevenueTypesEnum.Lessons ? (
              <StatsCardGraph data={filteredData} title={CoachLessonRevenueTypesEnum.Lessons} />
            ) : (
              <StatsCardGraph data={filteredData} title={CoachLessonRevenueTypesEnum.Revenue} />
            )}
          </section>
          <div className="items-center justify-center xs:mt-2 xs:flex md:mt-6 lg:hidden">
            <TimeRangeSelector
              options={TimeRangeOptionsEnums}
              selectedValue={selectedTimeRange}
              onSelect={setSelectedTimeRange}
            />
          </div>
        </div>
        <div className="w-full gap-6 md:flex  md:w-full md:flex-row lg:w-1/3 lg:flex-col">
          {/* Chart */}
          <section className="flex h-fit w-full flex-col rounded-xl border border-color-border-card-lightmode bg-color-bg-lightmode-primary p-6 shadow-sm dark:border-color-border-card-darkmode dark:bg-color-bg-darkmode-primary">
            <h2 className="typography-product-caption font-semibold	 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              {toTitleCase(title)}
            </h2>

            <div className="mt-5 flex w-full items-end gap-4">
              <div className="flex flex-1 shrink basis-0 flex-col">
                <p className="typography-product-display font-semibold text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                  {title === toTitleCase(CoachLessonRevenueTypesEnum.Lessons) ? count : `$${count}`}
                </p>
                <div className="mt-4 flex w-full items-center gap-2 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                  <span className="flex items-center justify-center gap-0.5 self-stretch whitespace-nowrap rounded-full py-0.5 text-color-text-brand">
                    {isProfit ? (
                      <ArrowUp className="h-5 w-5 text-brand-green-400 dark:text-brand-green-400 " />
                    ) : (
                      <ArrowDown className="h-5 w-5 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon " />
                    )}

                    <span
                      className={classNames(
                        'typography-product-element-label',
                        isProfit ? 'text-brand-green-400 ' : 'text-brand-red-500',
                      )}
                    >
                      {percentage}%
                    </span>
                  </span>
                  <span className="typography-product-caption flex-1 shrink basis-0 text-ellipsis text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                    vs last month
                  </span>
                </div>
              </div>
              {/* Area Chart */}
              <div>
                {isProfit ? (
                  <>
                    <StatsCardChart
                      activeTab={activeTab}
                      playerData={playerData}
                      isProfit={isProfit}
                    />
                  </>
                ) : (
                  <StatsCardChart
                    activeTab={activeTab}
                    playerData={playerData}
                    isProfit={isProfit}
                  />
                )}
              </div>
            </div>
          </section>
          {/* LessonStatsCard  */}
          <div className="w-full gap-6 xs:hidden md:flex">
            {activeTab === CoachLessonRevenueTypesEnum.Lessons ? (
              <LessonStatsCard
                lessonCount={playerData?.players?.[0]?.totalLessons ?? 0}
                playerCount={playerData?.players?.length ? playerData?.players?.length - 1 : 0}
                position={rankingsData?.rank || 0}
                city={playerData?.cityName || ''}
                openCoachActionsModal={openCoachActionsModal}
              />
            ) : (
              <RevenueStatsCard
                playerCount={playerData?.players?.length ? playerData?.players?.length - 1 : 0}
                openCoachActionsModal={openCoachActionsModal}
              />
            )}
          </div>
        </div>
      </div>
      <div className="items-center justify-center xs:mt-3 xs:hidden md:mt-6 lg:flex">
        <TimeRangeSelector
          options={TimeRangeOptionsEnums}
          selectedValue={selectedTimeRange}
          onSelect={setSelectedTimeRange}
        />
      </div>
    </>
  );
};
