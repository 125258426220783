import * as React from 'react';

export default function VictoryCup({
  className,
  viewBox = '0 0 144 131',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.8992 5.95312H63.2947L44.097 34.5112L24.8992 5.95312Z"
        fill="url(#paint0_radial_13585_95642)"
      />
      <path
        d="M24.8992 63.0664H63.2947L44.097 34.5083L24.8992 63.0664Z"
        fill="url(#paint1_radial_13585_95642)"
      />
      <path
        d="M36.6768 5.95312H51.5173L44.097 34.5112L36.6768 5.95312Z"
        fill="url(#paint2_radial_13585_95642)"
      />
      <path
        d="M36.6768 63.0664H51.5173L44.097 34.5083L36.6768 63.0664Z"
        fill="url(#paint3_radial_13585_95642)"
      />
      <path
        d="M80.6462 22.3164L61.2314 69.0192L44.0967 34.5092L80.6462 22.3164Z"
        fill="url(#paint4_radial_13585_95642)"
      />
      <path
        d="M26.9619 0L7.54707 46.7028L44.0966 34.5099L26.9619 0Z"
        fill="url(#paint5_radial_13585_95642)"
      />
      <path
        d="M61.7358 0.253906L80.4639 47.2363L44.0971 34.5089L61.7358 0.253906Z"
        fill="url(#paint6_radial_13585_95642)"
      />
      <path
        d="M7.73022 21.7812L26.4583 68.7636L44.097 34.5086L7.73022 21.7812Z"
        fill="url(#paint7_radial_13585_95642)"
      />
      <path
        d="M88.189 10.707L88.189 58.3126L44.0972 34.5098L88.189 10.707Z"
        fill="url(#paint8_radial_13585_95642)"
      />
      <path
        d="M0.00512695 10.707L0.00512441 58.3126L44.0969 34.5098L0.00512695 10.707Z"
        fill="url(#paint9_radial_13585_95642)"
      />
      <path
        d="M34.7698 53.236C35.4179 51.8748 36.7911 51.0078 38.2987 51.0078H49.8088C51.3164 51.0078 52.6896 51.8748 53.3377 53.236V53.236H34.7698V53.236Z"
        fill="url(#paint10_radial_13585_95642)"
      />
      <rect
        x="31.4807"
        y="53.2344"
        width="25.1463"
        height="8.70041"
        fill="url(#paint11_radial_13585_95642)"
      />
      <rect
        width="29.1782"
        height="2.12205"
        transform="matrix(1 0 0 -1 29.4646 64.0586)"
        fill="url(#paint12_radial_13585_95642)"
      />
      <path
        d="M30.738 20.8728C30.738 18.9644 30.8968 17.1149 31.1944 15.3555H57.0195C57.3172 17.1149 57.4759 18.9644 57.4759 20.8728C57.4759 30.634 53.3238 38.8559 47.6701 41.3313C43.1599 43.306 48.1641 51.0059 48.1641 51.0059H40.0498C40.0498 51.0059 44.943 43.3035 40.516 41.3652C34.8623 38.8898 30.738 30.634 30.738 20.8728Z"
        fill="url(#paint13_radial_13585_95642)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.7851 18.9102H23.9298C23.3515 18.9102 22.8648 19.3051 22.8206 19.8817C22.7752 20.4729 22.747 21.3609 22.799 22.6179C22.8662 24.2408 23.1144 26.4293 23.8526 28.6367C24.591 30.8451 25.8367 33.1208 27.9291 34.8452C29.8677 36.4429 32.4624 37.5087 35.8826 37.6692C35.3211 36.9734 34.7956 36.2074 34.3112 35.3798C32.1531 35.0326 30.5227 34.2329 29.2787 33.2077C27.5813 31.8087 26.5192 29.9199 25.8651 27.9637C25.1233 25.7455 24.8451 23.3667 24.907 21.0322H30.7291C30.7288 20.9792 30.7287 20.9261 30.7287 20.8731C30.7287 20.2112 30.7478 19.5565 30.7851 18.9102ZM52.271 37.667C52.8392 36.9693 53.3709 36.2006 53.861 35.3693C55.9873 35.0167 57.5976 34.2227 58.8292 33.2077C60.5266 31.8087 61.5887 29.9199 62.2429 27.9637C62.9846 25.7455 63.2629 23.3667 63.2009 21.0322H57.4662C57.4664 20.9792 57.4665 20.9261 57.4665 20.8731C57.4665 20.2112 57.4474 19.5565 57.4101 18.9102H64.1781C64.7564 18.9102 65.2432 19.3051 65.2874 19.8817C65.3327 20.4729 65.361 21.3609 65.3089 22.6179C65.2417 24.2408 64.9935 26.4293 64.2554 28.6367C63.5169 30.8451 62.2713 33.1208 60.1788 34.8452C58.2489 36.4358 55.6686 37.4991 52.271 37.667Z"
        fill="url(#paint14_radial_13585_95642)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.097 34.5112) rotate(-90) scale(28.5581 47.6436)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.458333" stop-color="#FC441B" stop-opacity="0.4" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.097 34.5083) rotate(90) scale(28.5581 47.6436)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.458333" stop-color="#FC441B" stop-opacity="0.4" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.097 34.5112) rotate(-90) scale(28.5581 18.415)"
        >
          <stop stop-color="#FF9D66" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF6C3E" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.097 34.5083) rotate(90) scale(28.5581 18.415)"
        >
          <stop stop-color="#FF9D66" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF6C3E" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.0967 34.5092) rotate(22.5732) scale(29.0691 62.7597)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.5" stop-color="#FC441B" stop-opacity="0.37" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.0966 34.5099) rotate(-157.427) scale(29.0691 62.7597)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.5" stop-color="#FC441B" stop-opacity="0.37" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.0971 34.5089) rotate(-21.7331) scale(29.0691 62.7597)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.364583" stop-color="#FC441B" stop-opacity="0.46" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.097 34.5086) rotate(158.267) scale(29.0691 62.7597)"
        >
          <stop stop-color="#FC441B" stop-opacity="0" />
          <stop offset="0.364583" stop-color="#FC441B" stop-opacity="0.46" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.0972 34.5098) scale(44.0917 59.072)"
        >
          <stop stop-color="#FF9D66" stop-opacity="0" />
          <stop offset="0.505208" stop-color="#FF9D66" stop-opacity="0.28" />
          <stop offset="1" stop-color="#FF6C3E" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.0969 34.5098) rotate(-180) scale(44.0917 59.072)"
        >
          <stop stop-color="#FF9D66" stop-opacity="0" />
          <stop offset="0.505208" stop-color="#FF9D66" stop-opacity="0.28" />
          <stop offset="1" stop-color="#FF6C3E" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0044 51.5422) rotate(28.3918) scale(12.1707 11.3798)"
        >
          <stop stop-color="#FC441B" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.8614 55.3212) rotate(57.3131) scale(26.8496 27.2785)"
        >
          <stop stop-color="#FC441B" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4392 0.597041) rotate(14.2936) scale(22.1367 65.4886)"
        >
          <stop stop-color="#FC441B" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF5937" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0535 22.1918) rotate(65.1768) scale(34.4689 30.345)"
        >
          <stop offset="0.0255617" stop-color="#FF782D" />
          <stop offset="0.522271" stop-color="#FF4920" stop-opacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_13585_95642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.5733 19.6784) rotate(41.0667) scale(25.8239 20.7798)"
        >
          <stop stop-color="#FF6610" />
          <stop offset="1" stop-color="#DF4320" stop-opacity="0.5" />
        </radialGradient>
      </defs>
    </svg>
  );
}
