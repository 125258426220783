import * as React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import AddPhotoV2 from 'svg/AddPhotoV2';
import classNames from 'styles/utils/classNames';

const DEFAULT_MAX_IMAGE_FILE_SIZE = 20 * 1024 * 1024;

const toMb = (bytes: number) => Math.round(bytes / 1024 / 1024);

interface Props extends DropzoneOptions {
  onSelectFiles(files: File[]): void;
  multiple?: boolean;
  styles?: string;
  uploadedFileUrl?: string;
  isVideo?: boolean;
  isImage?: boolean;
  classNameWrapper?: string;
  classNameImageContainer?: string;
}

export default function Dropzone({
  multiple = false,
  onSelectFiles,
  styles = '',
  uploadedFileUrl = '',
  isVideo = false,
  isImage = false,
  classNameWrapper = '',
  classNameImageContainer = '',
  ...rest
}: Props) {
  const onDrop = (acceptedFiles: File[]) => {
    onSelectFiles(acceptedFiles);
  };

  const accept = React.useMemo(() => {
    const acceptObj: { [key: string]: string[] } = {};
    if (isVideo) {
      acceptObj['video/*'] = ['.mp4', '.mov'];
    }
    if (isImage) {
      acceptObj['image/*'] = ['.png', '.jpg', '.jpeg'];
    }
    return acceptObj;
  }, [isVideo, isImage]);

  const fileTypeMessage = isVideo ? 'MP4, MOV' : isImage ? 'PNG, JPG' : 'MP4, MOV, PNG, JPG';

  const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
    onDrop,
    multiple,
    onFileDialogOpen: () => {
      onSelectFiles([]);
    },
    ...rest,
    maxSize: isImage ? rest.maxSize || DEFAULT_MAX_IMAGE_FILE_SIZE : undefined,
    accept: accept, // Ensure accept is never undefined
  });

  return (
    <div className={classNames(classNameWrapper ? classNameWrapper : 'flex w-full flex-col')}>
      <div
        {...getRootProps()}
        className={classNames(
          `flex w-full items-center justify-center bg-color-bg-lightmode-tertiary pb-8 pt-8`,
          styles,
        )}
      >
        <input {...getInputProps()} />
        <div className="flex cursor-pointer flex-col items-center text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder">
          <AddPhotoV2 className="h-8 w-8 [&>path]:fill-color-text-lightmode-icon" />
          <div>
            <span className="typography-product-body-highlight text-color-text-brand dark:text-color-text-brand">
              Click to upload{' '}
            </span>{' '}
            or drag and drop
          </div>
          <span>
            {fileTypeMessage} (max. {isImage ? `${toMb(DEFAULT_MAX_IMAGE_FILE_SIZE)} MB` : '5 Mins'}
            )
          </span>
        </div>
      </div>

      <div
        className={classNames(
          classNameImageContainer ? classNameImageContainer : 'mt-2 grid grid-cols-4 gap-1',
        )}
      >
        {acceptedFiles &&
          acceptedFiles.map((file, index) => {
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type.startsWith('video/');

            if (isImage) {
              return (
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt="Uploaded"
                  className="aspect-square w-full shrink-0 rounded-md border border-color-border-input-lightmode object-cover object-top dark:border-color-border-input-darkmode"
                />
              );
            }

            if (isVideo) {
              return (
                <video
                  key={index}
                  src={URL.createObjectURL(file)}
                  controls
                  className="aspect-square w-full shrink-0 rounded-md border border-color-border-input-lightmode object-cover object-top dark:border-color-border-input-darkmode"
                />
              );
            }
            return null;
          })}

        {!acceptedFiles?.length && uploadedFileUrl && (
          <img
            key={1}
            src={uploadedFileUrl}
            alt="Sponsor"
            className="aspect-square w-full shrink-0 rounded-md border border-color-border-input-lightmode object-cover object-top dark:border-color-border-input-darkmode"
          />
        )}
      </div>
    </div>
  );
}
