import React, { useState } from 'react';
import { CoachLessonRevenueTypesEnum } from 'utils/shared/coach/constants';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { useModal } from 'hooks/useModal';
import ModalCoachActions from 'components/modals/ModalCoachActions';
import { LessonStatsCard } from './LessonStatsCard';
import { RevenueStatsCard } from './RevenueStatsCard';
import { StatsCard } from './StatsCard';
import { TabSwitchProps } from '../types';

const TabSwitch: React.FC<TabSwitchProps> = ({ selectedPlayer, playerData, rankingsData }) => {
  const [activeTab, setActiveTab] = useState(CoachLessonRevenueTypesEnum.Lessons);
  const {
    isOpen: isModalCoachActionsOpen,
    openModal: openCoachActionsModal,
    closeModal: closeCoachActionsModal,
  } = useModal();

  return (
    <div className="w-full">
      {selectedPlayer && (
        <>
          <div className="flex items-center justify-center">
            <div className="relative flex flex-col items-center justify-center whitespace-nowrap rounded-3xl bg-color-bg-lightmode-secondary p-0.5 dark:bg-color-bg-darkmode-secondary xs:w-full md:w-80">
              <div className="relative flex w-full flex-1 justify-center">
                <div
                  className={`absolute bottom-0 left-0 top-0 w-1/2 rounded-3xl transition-transform duration-300 ${
                    activeTab === CoachLessonRevenueTypesEnum.Lessons
                      ? 'translate-x-0'
                      : 'translate-x-full'
                  } bg-color-bg-lightmode-brand dark:bg-color-bg-darkmode-brand`}
                ></div>
                <button
                  onClick={() => setActiveTab(CoachLessonRevenueTypesEnum.Lessons)}
                  className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 xs:h-8 md:h-10 ${
                    activeTab === CoachLessonRevenueTypesEnum.Lessons
                      ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
                      : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
                  }`}
                >
                  Lessons
                </button>
                <button
                  onClick={() => setActiveTab(CoachLessonRevenueTypesEnum.Revenue)}
                  className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 xs:h-8 md:h-10 ${
                    activeTab === CoachLessonRevenueTypesEnum.Revenue
                      ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
                      : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
                  }`}
                >
                  Revenue
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6">
            {activeTab === CoachLessonRevenueTypesEnum.Lessons && (
              <>
                <StatsCard
                  title="Lessons"
                  count={selectedPlayer.totalLessons ?? 0}
                  percentage={playerData?.growthLessons || 0}
                  isProfit={
                    playerData?.growthLessons && playerData?.growthLessons >= 0 ? true : false
                  }
                  activeTab={activeTab}
                  openCoachActionsModal={openCoachActionsModal}
                  playerData={playerData}
                  rankingsData={rankingsData}
                />
                <div className="xs:flex md:hidden">
                  <LessonStatsCard
                    lessonCount={playerData?.players?.[0]?.totalLessons ?? 0}
                    playerCount={playerData?.players?.length ? playerData?.players?.length - 1 : 0}
                    position={rankingsData?.rank || 0}
                    city={playerData?.cityName || ''}
                    openCoachActionsModal={openCoachActionsModal}
                  />
                </div>
              </>
            )}
            {activeTab === CoachLessonRevenueTypesEnum.Revenue && (
              <>
                <StatsCard
                  title="Total Revenue"
                  count={
                    convertUnitPriceToFormattedPrice(selectedPlayer.totalRevenue).priceFormatted ??
                    0
                  }
                  percentage={playerData?.growthRevenue || 0}
                  isProfit={
                    playerData?.growthRevenue && playerData?.growthRevenue >= 0 ? true : false
                  }
                  activeTab={activeTab}
                  openCoachActionsModal={openCoachActionsModal}
                  playerData={playerData}
                  rankingsData={rankingsData}
                />
                <div className="xs:flex md:hidden">
                  <RevenueStatsCard
                    playerCount={playerData?.newPlayers || 0}
                    openCoachActionsModal={openCoachActionsModal}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <ModalCoachActions
        isOpen={isModalCoachActionsOpen}
        closeModal={closeCoachActionsModal}
        title="Actions"
      />
    </div>
  );
};

export default TabSwitch;
