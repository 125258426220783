import classNames from 'classnames';
import { COACH_MARKETPLACE_PAGE } from 'constants/pages';
import Globe from 'svg/Globe';
import Link from 'components/Link';

interface Props {
  showOnMobile?: boolean;
}

const DiscoverButton: React.FC<Props> = ({ showOnMobile = false }) => {
  return (
    <div className={classNames(`flex justify-end px-9`, !showOnMobile ? 'max-md:hidden' : '')}>
      <Link
        href={COACH_MARKETPLACE_PAGE}
        className="flex items-center gap-3 rounded-full border border-color-border-input-lightmode py-1 pl-1 pr-6 shadow-[0_4px_16px_0_rgba(0,0,0,0.08)] dark:border-color-border-input-darkmode"
      >
        <div className="grid h-10 w-10 place-items-center rounded-full bg-color-brand-primary">
          <Globe className="h-5 w-5 text-color-text-lightmode-invert" />
        </div>
        <span className="typography-product-button-label-medium">Discover pickleball near you</span>
      </Link>
    </div>
  );
};

export default DiscoverButton;
