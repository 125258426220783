import * as React from 'react';

export default function PickleRacket({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Racket 2/Pickleball">
        <g id="Icon">
          <path
            d="M2.53109 4.66845C2.1533 3.63049 2.68848 2.48281 3.72643 2.10502L7.4852 0.736942C8.52316 0.359157 9.67084 0.894331 10.0486 1.93229L11.7587 6.63075C11.974 7.2223 11.8983 7.8807 11.5542 8.40788L10.1879 10.5015C9.70204 11.2458 8.78323 11.5803 7.93259 11.3223L5.54017 10.5968C4.93775 10.4141 4.45649 9.95846 4.24119 9.36691L2.53109 4.66845Z"
            fill="currentColor"
          />
          <path
            d="M8.08633 11.1599C7.89743 10.641 8.16502 10.0671 8.684 9.87822C9.20298 9.68932 9.77682 9.95691 9.96571 10.4759L10.9918 13.295C11.1807 13.8139 10.9131 14.3878 10.3941 14.5767C9.87512 14.7656 9.30128 14.498 9.11239 13.979L8.08633 11.1599Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
