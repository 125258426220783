import React, { useState } from 'react';
import Person from 'svg/User';
import { ButtonText } from 'components/Button';
import Link from 'components/Link';
import ModalShareProfile from 'components/modals/ModalShareProfile/ModalShareProfile';

interface InvitePlayersSectionProps {
  openCoachActionsModal: () => void;
}

const InvitePlayersSection: React.FC<InvitePlayersSectionProps> = ({ openCoachActionsModal }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <div className="flex justify-center px-9">
      <ButtonText onClick={() => setIsShareModalOpen(true)}>
        <div className="flex items-center gap-3 rounded-full border border-color-border-input-lightmode py-1 pl-1 pr-6 shadow-[0_4px_16px_0_rgba(0,0,0,0.08)] dark:border-color-border-input-darkmode">
          <div className="grid h-10 w-10 place-items-center rounded-full bg-color-brand-primary">
            <Person className="h-5 w-5 text-color-text-lightmode-invert" />
          </div>
          <span className="typography-product-button-label-medium">Share your profile</span>
        </div>
      </ButtonText>

      <ModalShareProfile
        isOpen={isShareModalOpen}
        closeModal={() => setIsShareModalOpen(false)}
        shareUrl=""
        title="Share your profile"
      />
    </div>
  );
};

export default InvitePlayersSection;
