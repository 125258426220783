import React from 'react';
import Plus from 'svg/PlusWithoutBG';
import { Button } from 'components/Button';

interface RevenueStatsCardProps {
  playerCount: number;
  openCoachActionsModal: () => void;
}

export const RevenueStatsCard: React.FC<RevenueStatsCardProps> = ({
  openCoachActionsModal,
  playerCount,
}) => {
  return (
    <div className="flex w-full flex-col items-center rounded-xl border border-color-border-card-lightmode bg-color-bg-lightmode-primary p-6 shadow-sm dark:border-color-border-card-darkmode xs:mt-6 md:mt-0 lg:mb-10">
      <div className="mb-6 flex flex-col items-center justify-center gap-4">
        <h6 className="typography-informative-caption text-center text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          Client growth
        </h6>
        <h4 className="lg:typography-informative-quote xs: xs:text-center lg:text-start">
          You have <span className="text-color-text-brand">{playerCount}</span> new{' '}
          {playerCount === 1 ? 'player' : 'players'} this week{' '}
        </h4>
      </div>
      <div className="flex xs:justify-center md:justify-start">
        <Button
          variant="brand"
          label="Add Clients"
          size="md"
          iconLeft={<Plus className="w-5" />}
          onClick={openCoachActionsModal}
          className="xs:h-10 xs:w-48"
        />
      </div>
    </div>
  );
};
