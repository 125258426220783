import { format } from 'date-fns';

export function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) return 'th'; // special case for 11th to 20th
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

function getFormatDateWithSuffix(date: Date): string {
  const day = Number(format(date, 'd'));
  const dayWithSuffix = `${day}${getDaySuffix(day)}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'yyyy');

  return `${month} ${dayWithSuffix}, ${year}`;
}

export default getFormatDateWithSuffix;
