import React from 'react';
import { format } from 'date-fns';
import NextImage from 'next/image';
import toTitleCase from 'utils/shared/string/toTitleCase';
import License from 'svg/License';
import Location from 'svg/Location';
import LessonBadge from 'components/tournaments/LessonBadge';
import { LessonProps } from '../types';

const DEFAULT_GROUP_IMAGE = '/images/app/default_group_image.png';

const LessonCard: React.FC<LessonProps> = ({ lesson }) => {
  return (
    <div className="mt-4 flex w-full flex-col">
      <div className="flex w-full flex-col">
        <h6 className="typography-product-element-label text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {format(new Date(lesson?.startDateTime), 'EEE dd, HH:mm')} -{' '}
          {format(new Date(lesson?.endDateTime), 'HH:mm')}
        </h6>
        <div className="mt-3 flex w-full items-center gap-4">
          <NextImage
            loading="lazy"
            width={80}
            height={80}
            src={lesson?.ownerProfile?.profileImageProviderUrl || DEFAULT_GROUP_IMAGE}
            alt={`${lesson?.ownerProfile?.profileImagePath} lesson`}
            className="my-auto aspect-square shrink-0 self-stretch rounded-lg object-cover"
          />
          <div className="flex flex-col self-stretch">
            <LessonBadge label={toTitleCase(lesson.sport)} variant={lesson.type} size="xs" />
            <div className="typography-product-text-card mt-1 flex w-full flex-col">
              <div className="flex w-full flex-row items-center gap-1">
                <Location
                  className="mr-1 h-5 w-5 flex-shrink-0 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon"
                  viewBox="0 0 20 20"
                />

                <p className="typography-product-text-card my-auto flex-grow text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                  {lesson?.userCustomCourt?.fullAddress ||
                    lesson?.venue?.addressString ||
                    'Address not available'}
                </p>
              </div>

              <div className="mt-1 flex w-full items-center gap-1">
                <License
                  className="mr-1 h-5 w-5 flex-shrink-0 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon"
                  viewBox="0 0 20 20"
                />
                <p className="typography-product-text-card my-auto text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                  {toTitleCase(
                    (lesson?.userCustomCourt?.title || lesson?.venue?.title) ??
                      'Title not available',
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCard;
