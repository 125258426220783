import React from 'react';
import { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Switch from 'components/forms/Switch';
import classNames from 'styles/utils/classNames';

type Props = {
  label?: string;
  name: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  isEdit?: boolean;
  classNameLabel?: string;
};

const Switcher = ({ label, errors, control, name, isEdit, classNameLabel }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { value, onChange } }) => {
        return (
          <div className="flex [&>div]:mr-4 [&>div]:lg:ml-0">
            <Switch
              isActive={value}
              toggleIsActive={() => {
                if (!isEdit) {
                  onChange(!value);
                }
              }}
              disabled={isEdit}
            />
            {label && (
              <span
                className={classNames(
                  'font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
                  classNameLabel,
                )}
              >
                {label}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default Switcher;
