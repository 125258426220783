export enum ForNavigationType {
  Coach = 'coach',
  Event = 'event',
}

export interface StripeOnboardingProps {
  isOpen: boolean;
  handleClose: (didAddBankInformation: boolean) => void;
  navigateTo?: ForNavigationType;
  resourceId?: string;
}
