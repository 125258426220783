import styled from 'styled-components';
import { CardsInRowProps } from './props';
import { breakpoints, getCardsInRow } from './utils';

export const GridContainer = styled.div<{ cardsInRow: CardsInRowProps; gap: string }>`
  display: grid;
  gap: ${(props) => props.gap};
  grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'base')}, 1fr);

  @media ${breakpoints.xs} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'xs')}, 1fr);
  }
  @media ${breakpoints.sm} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'sm')}, 1fr);
  }
  @media ${breakpoints.md} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'md')}, 1fr);
  }
  @media ${breakpoints.lg} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'lg')}, 1fr);
  }
  @media ${breakpoints.xl} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'xl')}, 1fr);
  }
  @media ${breakpoints.xxl} {
    grid-template-columns: repeat(${(props) => getCardsInRow(props.cardsInRow, 'xxl')}, 1fr);
  }
`;
