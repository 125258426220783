import Stripe from 'stripe';
import api from 'services/client/api';
import { ForNavigationType } from 'components/modals/ModalStripeOnboarding/types';

interface Response {
  accountLink: Stripe.AccountLink;
}

export const initializeConnectOnboarding = async (
  idToken: string,
  navigateTo: ForNavigationType,
  resourceId: string,
) => {
  const response = await api.post('v1/payment-providers/stripe/connect/onboarding', {
    payload: { token: idToken, navigateTo, resourceId },
  });
  return response as Response;
};
